// NavFooter.js
import { connect } from 'react-redux';

import NavFooter from '../component/NavFooter.jsx';
import withRouter from '../component/WithRouter.jsx';

import getMeData from '../selector/getMeData.js';

const mapStateToProps = (state, { location }) => {
  return {
    meId: getMeData(state, 'id'),
    meUsername: getMeData(state, 'username'),
    pathname: location.pathname,
  };
};

export default withRouter(connect(mapStateToProps, null)(NavFooter));
