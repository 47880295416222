// NavFooter.jsx
'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getShouldShowBottomNavigation } from '../resource/getShouldShowFooter.js';
import { getIsIphoneXSeriesPWA } from '../resource/getUserAgent.js';
import {
  footerNavigationHeight,
  iPhoneXSeriesFooterPaddingInPWA,
} from '../style/variables.js';

import BottomNavigation from '../container/BottomNavigation.js';

export class NavFooter extends React.PureComponent {
  state = { isServer: true };
  nextTick = null;

  componentDidMount() {
    this.nextTick = setTimeout(() => this.setState({ isServer: false }), 0);
  }

  componentWillUnmount() {
    clearTimeout(this.nextTick);
  }

  render() {
    const { meId, meUsername, pathname } = this.props;
    const { isServer } = this.state;
    const shouldShowFooter = getShouldShowBottomNavigation({
      pathname,
      meId,
      meUsername,
    });
    if (!shouldShowFooter) {
      return null;
    }
    return (
      <StyledNavFooter>
        <BottomNavigation
          footerNavigationHeight={
            !isServer && getIsIphoneXSeriesPWA()
              ? footerNavigationHeight + iPhoneXSeriesFooterPaddingInPWA
              : footerNavigationHeight
          }
        />
      </StyledNavFooter>
    );
  }
}

NavFooter.propTypes = {
  meId: PropTypes.string,
  meUsername: PropTypes.string,
  pathname: PropTypes.string,
};

NavFooter.defaultProps = {
  meId: null,
  meUsername: '',
  pathname: '',
};

const StyledNavFooter = styled.aside`
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: #191919;
  z-index: 10;
`;

export default NavFooter;
